import React from "react";
import "./footer.css"

const Footer = () => {
  return (
    <>
      {/*<!-- ======= Footer ======= -->*/}
      <footer id="footer">
        <div className="container">
          <h3>Anna Laura</h3>
          <p>Minha festa de 15 anos</p>
        <div className="copyright">
            &copy; Copyright <strong><span>Anna Laura</span></strong>. All Rights Reserved
          </div>
        </div>
      </footer>{/*<!-- End Footer -->*/}
    </>
  );
};

export default Footer;
