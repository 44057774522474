import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/admin.css";
import BASE_URL from "./../utils/config";

const Admin = () => {
  const [pessoas, setPessoas] = useState([]);
  const [searchGuests, setSearchGuests] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${BASE_URL}/convidados`);
        setPessoas(result.data.data);
        setIsLoading(false);
      } catch (error) {
        setError("Failed to fetch data");
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredPessoas = pessoas.filter((pessoa) =>
    pessoa.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    pessoa.convidados.join(", ").toLowerCase().includes(searchGuests)
  );




  const getTotalConvidadas = () => {
    return filteredPessoas.reduce((total, pessoa) => {
      const confirmedGuests = pessoa.convidados.filter(
        (convidado) => pessoa.confirmation === "confirmed"
      ).length;
      return total + confirmedGuests;
    }, 0);
  };

  const getTotalConfirmedGuests = () => {
    return filteredPessoas.reduce((total, pessoa) => {
      const confirmedPessoasGuests = [pessoa.name].filter(()=> pessoa.confirmation==="confirmed").length;
      return total + confirmedPessoasGuests;
    }, 0);
  };
  return (
    <>
      <main
        id="main"
        style={{ paddingTop: "80px", backgroundColor: "#ffccd5" }}
      >
        <div className="container">
          <header className="section-title">
            <h2>Admin</h2>
            <p>Lista de convidados</p>
          </header>
          <div className="whitebox" style={{overflow:"auto"}}>
            <div className="row gy-4 justify-content-center mb-5">
              <div className="col-lg-6">
                <div className="php-email-form">
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        name="user"
                        placeholder="Buscar por nome"
                        required
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        name="user"
                        placeholder="Buscar por convidado"
                        required
                        onChange={(e) => setSearchGuests(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table className="table">
              {isLoading ? (
                <p className="text-center">Carregando...</p>
              ) : error ? (
                <p className="text-center">{error}</p>
              ) : (
                <table className="table" style={{ backgroundColor: "#ffccd5"}}>
                  <thead style={{ backgroundColor: "#ffccd5" }}>
                    <tr style={{ backgroundColor: "#ffccd5" }}>
                      <th scope="col" style={{ backgroundColor: "#ff758f" }}>
                        Nome
                      </th>
                      <th scope="col" style={{ backgroundColor: "#ff758f" }}>
                        Convidados
                      </th>
                      <th scope="col" style={{ backgroundColor: "#ff758f" }}>
                        Quantidade
                      </th>
                      <th scope="col" style={{ backgroundColor: "#ff758f" }}>
                        Telefone
                      </th>
                      <th scope="col" style={{ backgroundColor: "#ff758f" }}>
                        Confirmação
                      </th>
                      <th scope="col" style={{ backgroundColor: "#ff758f" }}>
                        Mensagem
                      </th>
                      <th scope="col" style={{ backgroundColor: "#ff758f" }}>
                        Ação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPessoas.map((pessoa) => (
                      <tr key={pessoa._id}>
                        <td style={{ backgroundColor: "#ff8fa3" }}>
                          {pessoa.name}
                        </td>
                        <td style={{ backgroundColor: "#ff8fa3" }}>
                          {pessoa.convidados.join(", ")}
                        </td>
                        <td style={{ backgroundColor: "#ff8fa3" }}>
                          {pessoa.convidados.length}
                        </td>
                        <td style={{ backgroundColor: "#ff8fa3" }}>
                          {pessoa.tel}
                        </td>
                        <td style={{ backgroundColor: "#ff8fa3" }}>
                          {pessoa.confirmation === "confirmed" && (
                            "Confirmado"
                          )
                          }
                          {pessoa.confirmation === "notconfirmed" && (
                            "Não vai"
                          )
                          }
                        </td>
                        <td style={{ backgroundColor: "#ff8fa3" }}>
                          {pessoa.message}
                        </td>
                        <td style={{ backgroundColor: "#ff8fa3" }}>
                          <a
                            className="btn btn-primary btn-circle m-1"
                            title="View Details"
                            href={"https://wa.me/55" + pessoa.tel}
                            style={{ backgroundColor: "#ff758f", border:"none" }}
                          >
                            Ligar
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="1" style={{ backgroundColor: "#ff758f" }}>
                        Total de formularios: {getTotalConfirmedGuests()}
                      </td>
                      <td colSpan="1" style={{ backgroundColor: "#ff758f" }}>
                        Total de convidados: {getTotalConvidadas()}
                      </td>
                      <td colSpan="5" style={{ backgroundColor: "#ff758f" }}>
                        Total (confirmados): {getTotalConvidadas()+getTotalConfirmedGuests()}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
};

export default Admin;
