import React, { useEffect } from "react";
import "./header.css"
import { Link } from "react-router-dom";
import select from "../../utils/select";
import offscroll from "../../utils/offScroll";
import onscroll from "../../utils/onScroll";
import on from "../../utils/on"

const Header = () => {
  useEffect(() => {
    /**
   * Scrolls to an element with header offset
   */
    const scrollto = (el) => {
      let header = select('#header')
      if (header) {
        let offset = header.offsetHeight
        let headerClassList = header.classList
        if(headerClassList){
          if (!headerClassList.contains('header-scrolled')) {
            offset -= 20
          }
        }
        let elementPos = select(el).offsetTop
        window.scrollTo({
          top: elementPos - offset,
          behavior: 'smooth'
        })
      }
      }

    const navbarlinksActive = () => {
      const position = window.scrollY + 200;
      const navbarlinks = select("#navbar .scrollto", true);
      if (navbarlinks) {
        navbarlinks.forEach((navbarlink) => {
          if (!navbarlink.hash) return;
          const section = select(navbarlink.hash);
          if (!section) return;
          if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
            navbarlink.classList.add("active");
          } else {
            navbarlink.classList.remove("active");
          }
        });
      }
    };

    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function(e) {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })
  

  /**
   * Mobile nav dropdowns activate
   */
  on('click', '.navbar .dropdown > a', function(e) {
    if (select('#navbar').classList.contains('navbar-mobile')) {
      e.preventDefault()
      this.nextElementSibling.classList.toggle('dropdown-active')
    }
  }, true)

  
    /**
     * Scrool with ofset on links with a className name .scrollto
     */
    on('click', '.scrollto', function(e) {
      if (select(this.hash)) {
        e.preventDefault()
  
        let navbar = select('#navbar')
        if (navbar) {
          if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
          }
        }
        scrollto(this.hash)
      }
    }, true)


    const handleScroll = () => {
      const header = select("#header");
      if (header) {
        if (window.scrollY > 100) {
          header.classList.add("header-scrolled");
        } else {
          header.classList.remove("header-scrolled");
        }
      }
    };

    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = select(hash);
        if (element) {
          scrollto(hash);
        }
      }
    };

    onscroll(document, navbarlinksActive);
    onscroll(document, handleScroll);
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      offscroll(document, navbarlinksActive);
      offscroll(document, handleScroll);
    };
  }, []);

  return (
    <>
      <header id="header" className="fixed-top d-flex justify-content-center align-items-center header-transparent">
        <nav id="navbar" className="navbar">
          <ul>
            <li key="hero">
              <Link
                className="nav-link scrollto active"
                to="/home/#hero"
              >
                Início
              </Link>
            </li>
            <li key="about">
              <Link className="nav-link scrollto" to="/home/#about">
                Sobre mim
              </Link>
            </li>
            <li key="portfolio">
              <Link className="nav-link scrollto" to="/home/#portfolio">
                Fotos
              </Link>
            </li>
            <li key="gifts">
              <Link className="nav-link scrollto" to="/home/#gifts">
                Presentes
              </Link>
            </li>
            <li key="contact">
              <Link className="nav-link scrollto" to="/home/#contact">
                Save the date
              </Link>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </header>
    </>
  );
};

export default Header;