import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Home from './../pages/Home';
import Login from './../pages/Login';
import Admin from './../pages/Admin';
import Success from './../pages/Success'
import NotFoundPage from './../pages/NotFoundPage';

const Routers = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/home' />} />
      <Route path='/home' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/admin' element={<Admin />} />
      <Route path='/success' element={<Success />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routers;
