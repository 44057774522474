import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./../../utils/config";
import "./contact.css";
import Maps from "../Maps/Maps";

const Contact = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    tel: "",
    message: "",
    confirmation: "confirmed",
    convidados: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/convidados`, formData);
      navigate("/success");
    } catch (error) {
      alert("Aconteceu um erro, por favor, tente novamente mais tarde.");
    }
  };

  const addConvidado = () => {
    if (formData.convidados.length < 6) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        convidados: [...prevFormData.convidados, ""],
      }));
    } else {
      alert("Máx de convidados atingido");
    }
  };

  const removerConvidado = () => {
    setFormData((prevFormData) => {
      const updatedConvidados = prevFormData.convidados.slice(0, -1);
      return { ...prevFormData, convidados: updatedConvidados };
    });
  };

  const handleConvidadoChange = (index, value) => {
    setFormData((prevFormData) => {
      const updatedConvidados = [...prevFormData.convidados];
      updatedConvidados[index] = value;
      return { ...prevFormData, convidados: updatedConvidados };
    });
  };

  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <span>Save the date</span>
            <h2>Save the date</h2>
          </div>

          <div className="row d-flex justify-content-between">
            <Maps />

            <div
              className="col-lg-6 justify-content-between"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <div className="row">
                <div className="info-box">
                  <i className="bx bx-pin"></i>
                  <h3>Data</h3>
                  <div className="container">
                    <div className="row">
                      <div className="d-flex justify-content-center text-center">
                        <div className="m-2" style={{ width: "80px" }}>
                          <h3>24/03</h3>
                        </div>
                        <div className="m-2" style={{ width: "80px" }}>
                          <h3>18:30</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"></div>
              <div className="row">
                <form onSubmit={handleSubmit} className="php-email-form">
                  <div className="info-box" style={{ boxShadow: "none" }}>
                    <i className="bx bx-pin"></i>
                    <h3>Confirme sua presença</h3>
                    <p>
                      Por favor, se houver, insira o nome dos
                      familiares que irá levar.
                    </p>
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      placeholder="Seu nome"
                      required
                    />
                  </div>
                  <div
                    className="form-group mt-1
"
                  >
                    <input
                      type="tel"
                      name="tel"
                      className="form-control"
                      value={formData.tel}
                      onChange={(e) =>
                        setFormData({ ...formData, tel: e.target.value })
                      }
                      placeholder="(xx) xxxx-xxxx"
                      required
                    />
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <button onClick={addConvidado} type="button">
                        Vou levar familiar
                      </button>
                    </div>
                    <div className="col-md-6">
                      {formData.convidados.length >= 1 && (
                        <button onClick={removerConvidado} type="button">
                          Remover
                        </button>
                      )}
                    </div>
                  </div>
                  {formData.convidados.map((convidado, index) => (
                    <div key={index} className="col-md-12 mt-1 mb-1">
                      <input
                        type="text"
                        name={`convidado${index + 1}`}
                        value={convidado.nome}
                        onChange={(e) =>
                          handleConvidadoChange(index, e.target.value)
                        }
                        className="form-control"
                        placeholder={`Convidado: ${index + 1}`}
                      />
                    </div>
                  ))}
                  <div className="form-group mt-3">
                    <textarea
                      name="message"
                      value={formData.message}
                      className="form-control"
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                      placeholder="Escreva uma pequena mensagem para mim"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3 form-group">
                    <select
                      name="confirmation"
                      className="form-control"
                      value={formData.confirmation}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirmation: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="confirmed">Já estou pronto!</option>
                      <option value="notconfirmed">Não vou poder ir</option>
                    </select>
                  </div>
                  <button type="submit">Enviar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
