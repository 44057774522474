import React from "react";
import "./hero.css"
const Hero = () => {

  return (
    <>
      {/* ======= Hero Section ======= */}
      <section id="hero">
        <div className="hero-container">
          <h1>Anna Laura</h1>
          <h2>Minha festa de 15 anos</h2>
          <a href="#about" className="btn-scroll scrollto" title="Scroll Down">
            <i className="bx bx-chevron-down"></i>
          </a>
          <div className="row audio">
            <audio
              src="/assets/media/bruno.mp3"
              autoPlay
              controls
              controlsList="nodownload noplaybackrate"
            />
          </div>
        </div>
      </section>
      {/* End Hero */}
    </>
  );
};

export default Hero;
