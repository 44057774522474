import React from "react";

const Maps = () => {
  return (
    <>
      <div className="col-lg-6 local" style={{marginTop:"20px"}}>
        <div className="row h-100">
          <div className="info-box h-100">
            <div className="col-md-12">
              <i className="bx bx-pin"></i>
              <h3>Localização</h3>
              <div className="social-link" style={{padding: "30px"}}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240.219713568809!2d-43.39013505433131!3d-22.966801139188302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd95c27ca9adf%3A0x9d31d37695f405a2!2sBallroom%20Barra%20Ol%C3%ADmpica!5e0!3m2!1spt-BR!2sbr!4v1707952358698!5m2!1spt-BR!2sbr"
                  title="maps"
                  width="100%"
                  height="450"
                  allowFullScreen=""
                  loading="async"
                  referrerPolicy="no-referrer-when-downgrade"
                  style={{border:"0"}}
                />
              </div>
              <img
                src="/assets/img/local.jpg"
                alt=""
                width="100%"
                height="100%"
              />
              <p>
                Estr. Cel. Pedro Corrêa, 108 - Jacarepaguá, Rio de Janeiro - RJ,
                22775-090
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maps;
