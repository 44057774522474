import React from "react";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div>
      <div
        className=""
        style={{
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffccd5",
        }}
      >
        <header className="section-title">
          <h2>Confirmado!</h2>
          <p style={{ color: "#ff4d6d" }}>Estarei te esperando!</p>
          <br />
          <Link to={"/home"} style={{ color: "#000" }}>
            Volte para o início
          </Link>
        </header>
      </div>
    </div>
  );
};

export default SuccessPage;
