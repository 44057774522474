import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from "../../utils/config";
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./testimonials.css"

const Testimonials = () => {
        const [pessoas, setPessoas] = useState([]);
        const [searchTerm] = useState("");
        const [isLoading, setIsLoading] = useState(true);
        const [error, setError] = useState(null);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const result = await axios.get(`${BASE_URL}/convidados`);
              setPessoas(result.data.data);
              setIsLoading(false);
            } catch (error) {
              setError("Failed to fetch data");
              setIsLoading(false);
            }
          };
      
          fetchData();
        }, []);
      
        const filteredPessoas = pessoas.filter((pessoa) =>
        pessoa.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return (
        <>
        {isLoading ? (
              <div></div>
            ) : error ? (
              <div></div>
            ) : (
            <section id="testimonials" className="testimonials">
                <div className="container position-relative">

                    <Swiper className="testimonials-slider swiper" data-aos="zoom-in" data-aos-delay="100" modules={[Pagination, A11y, Autoplay]}
                            loop={true}
                            speed={600}
                            autoplay={{ delay: 5000, disableOnInteraction: false }}
                            spaceBetween={20}
                            slidesPerView="auto"
                            slidesPerGroup={1}
                            pagination={{ clickable: true, el: '.swiper-pagination', type: 'bullets' }}>
                        <div className="swiper-wrapper">

                            {filteredPessoas?.map((pessoa, index) => (
                                <SwiperSlide className="swiper-slide" key={index}>
                                    <div className="testimonial-item">
                                        <h3>{pessoa.name}</h3>
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            {pessoa.message}
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </div>
                        <div className="swiper-pagination"></div>
                    </Swiper>

                </div>
            </section>
            )}
        </>
    );
}

export default Testimonials;
