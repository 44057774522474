import React from 'react';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import './client.css'

const Client = () => {

    return (
        <>
            {/* ======= Clients Section ======= */}
            <section id="gifts" className="clients">

                <div className="container">

                    <header className="section-title">
                        <span>Gifts</span>
                        <h2>Gifts</h2>
                        <p>Sugestão de lojas</p>
                    </header>
                    <Swiper 
                        className='clients-slider'
                        modules={[Pagination, A11y, Autoplay]}
                        loop={true}
                        autoplay={{ delay: 1000, disableOnInteraction: false }}
                        spaceBetween={20}
                        slidesPerView={"auto"}
                        slidesPerGroup={1}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 40
                            },
                            480: {
                                slidesPerView: 3,
                                spaceBetween: 60
                            },
                            640: {
                                slidesPerView: 4,
                                spaceBetween: 80
                            },
                            992: {
                                slidesPerView: 6,
                                spaceBetween: 120
                            }
                        }
                        }
                        pagination={{ clickable: true, el: '.swiper-pagination', type:'bullets' }}
                    >
                        <div className="swiper-wrapper align-items-center">
                            <SwiperSlide className='swiper-slide'><a href="https://www.cea.com.br/"><img src="/assets/img/gifts/c&a.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.lojacomplemento.com.br/"><img src="/assets/img/gifts/complemento.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.dressto.com.br/"><img src="/assets/img/gifts/dress_to.jpg" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.farmrio.com.br/"><img src="/assets/img/gifts/farm.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://havaianas.com.br/"><img src="/assets/img/gifts/havaianas.jpg" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://loja.imaginarium.com.br/"><img src="/assets/img/gifts/imaginarium.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.melissa.com.br/"><img src="/assets/img/gifts/melissa.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.nike.com.br/"><img src="/assets/img/gifts/nike.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.pandorajoias.com.br/"><img src="/assets/img/gifts/pandora.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.sephora.com.br/"><img src="/assets/img/gifts/sephora.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                            <SwiperSlide className='swiper-slide'><a href="https://www.zara.com/"><img src="/assets/img/gifts/zara.png" className="img-fluid" alt=""></img></a></SwiperSlide>
                        </div>
                        <div className="swiper-pagination"></div>
                    </Swiper>

                    
                        </div>
                        
            </section>{/* End Clients Section */}
        </>
    );
}

export default Client;
