import React, { useEffect } from "react";
import Isotope from 'isotope-layout'
import "./portfolio.css"

const Portfolio = () => {
const [isotope, setIsotope] = React.useState(null);

React.useEffect(() => {
  setIsotope(
    new Isotope('.filter-container', {
      itemSelector: '.portfolio-item', // filter-item: className of the isotope elements
      layoutMode: 'fitRows', // for horizontal isotope
    })
  );
}, []);

  return (
    <>
      {/* ======= My Portfolio Section ======= */}
      <section id="portfolio" className="portfolio">
        <div className="container">
          <div className="section-title">
            <span>Minhas fotos</span>
            <h2>Fotos</h2>
          </div>

          <div className="row portfolio-container">
            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-13.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-14.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-15.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-16.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-17.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-19.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-20.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-21.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-9.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-11.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-12.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-1.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-2.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-3.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-4.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-5.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-18.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-6.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-7.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-8.jpeg"
                  alt=""
                ></img>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item">
              <div className="portfolio-img">
                <img
                className="img-fluid"
                  src="/assets/img/portfolio/Foto-22.jpeg"
                  alt=""
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End My Portfolio Section */}
    </>
  );
};

export default Portfolio;
