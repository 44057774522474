import React from "react";
import "../styles/home.css";

import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import Client from "../components/Client/Client";
import Portfolio from "../components/Portfolio/Portfolio";
import Testimonials from "../components/Testimonials/Testimonials";
import Contact from "../components/Contact/Contact";

const Home = () => {

  return (
    <>
      <Hero />

      <main id="main">

        <About></About>

        <Testimonials></Testimonials>

        <Portfolio></Portfolio>
            
        <Client></Client>

        <Contact></Contact>

      </main>{/* End #main */}
      
      <a href="/#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </>
  );
};
export default Home;
