import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => (
  <div>
    <div
      className=""
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffccd5",
      }}
    >
      <header className="section-title">
        <h2>404</h2>
        <p style={{ color: "#ff4d6d" }}>Ops! Essa página não existe.</p>
            <Link to={"/home"} style={{ color: "#000" }}>Volte para o início</Link>
      </header>
      </div>
      </div>
);

export default NotFoundPage;
