import React from 'react';
import "./about.css"
const About = () => {
    return (
        <>
        {/* ======= About Me Section ======= */}
        <section id="about" className="about">
          <div className="container">

            <div className="section-title">
              <span>Sobre mim</span>
              <h2>Sobre mim</h2>
            </div>

            <div className="row">
              <div className="image col-lg-4 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
              <div className="col-lg-8 d-flex flex-column align-items-stretch">
            <section id="features" className="features">

              <div className="container aos-init aos-animate" data-aos="fade-up">
        
                <div className="row">
        
                  <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
                    <div className="row align-self-center gy-2">
        
                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um talento</p>
                            <h3>Desenho</h3>
                          </div>
                        </div>
                      </div>
        
                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="300">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um Esporte</p>
                            <h3>Balé</h3>
                          </div>
                        </div>
                      </div>
        
                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um cantor</p>
                            <h3>Burno Mars</h3>
                          </div>
                        </div>
                      </div>
        
                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="500">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Uma comida</p>
                            <h3>Baião de dois</h3>
                          </div>
                        </div>
                      </div>
        
                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="600">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um restaurante</p>
                            <h3>Madero</h3>
                          </div>
                        </div>
                      </div>
        
                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="700">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um parque</p>
                            <h3>Disney/ universal</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="700">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Estudou</p>
                            <h3>Francês/ inglês / espanhol</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="700">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Uma cor</p>
                            <h3>Rosa</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="700">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um animal</p>
                            <h3>Golfinho</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="700">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Viajou</p>
                            <h3>28 países</h3>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="700">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um time</p>
                            <h3>Botafogo</h3>
                          </div>
                        </div>
                      </div>
        
                      <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay="700">
                        <div className="feature-box d-flex align-items-center">
                          <i className="bi bi-check"></i>
                          <div className="text-box" style={{width: "100%"}}>
                            <p>Um filme</p>
                            <h3>Harry Potter</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        
                </div>
        
              </div>
        
            </section>
          </div>
            </div>

          </div>
        </section>{/* End About Me Section */}
        </>
    );
}

export default About;
