import React, { useState } from "react";
import axios from "axios";
import BASE_URL from "../utils/config";
import "../styles/login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
 const navigate = useNavigate();
 const [credentials, setCredentials] = useState({
   username: "",
   password: "",
 });

 const handleChange = (e) => {
   const { name, value } = e.target;
   setCredentials((prevCredentials) => ({
     ...prevCredentials,
     [name]: value,
   }));
 };

 const handleSubmit = async (e) => {
   e.preventDefault();

   try {
     await axios.post(`${BASE_URL}/auth/login`, credentials);
     navigate("/admin");
   } catch (error) {
     alert("Senha ou nome incorreto");
     console.error(error);
   }
 };

 return (
   <>
     <div
       className=""
       style={{ height: "100vh", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", backgroundColor:"#ffccd5" }}
     >
       <header className="section-title">
         <h2>Login</h2>
         <p style={{color:"#ff4d6d"}}>Apenas para administradores</p>
       </header>

       {/* <!-- ======= Contact Me Section ======= --> */}
       <section id="admin" className="contact">
         <div className="container">
           <div className="row d-flex align-items-center justify-content-center">
             <form onSubmit={handleSubmit} method="post" className="php-email-form">
               <div className="form-group">
                 <input
                   type="text"
                   name="username"
                   className="form-control"
                   id="username"
                   placeholder="Seu nome"
                   onChange={handleChange}
                   required
                 />
               </div>

               <div className="form-group mt-1">
                 <input
                   type="password"
                   name="password"
                   className="form-control"
                   id="password"
                   placeholder="xxxxxxxxxx"
                   onChange={handleChange}
                   required
                 />
               </div>

               <div className="text-center mt-4">
                 <button type="submit">Enviar</button>
               </div>
             </form>
           </div>
         </div>
       </section>
       {/*<!-- End Contact Me Section -->*/}
     </div>
   </>
 );
};

export default Login;